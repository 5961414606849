import styled from "@emotion/styled";
import { ImageCard, ImageCardProps } from "../ImageCard/ImageCard";

const Wrapper = styled("div")`
    display: flex;
    justify-content: flex-start;
    overflow: scroll;
    width: 100%;
    gap: 20px;
    ::-webkit-scrollbar {
        display: none;
    }
`;

const StyledSlide = styled("div")``;

interface CardSwiperProps {
    cardVariant: "round" | "square";
    items: (Omit<ImageCardProps, "variant"> & {
        onClick: () => void;
        id: number;
    })[];
}

export const CardScroll = (props: CardSwiperProps) => {
    const { cardVariant, items } = props;

    return (
        <Wrapper>
            {items.map((item) => (
                <StyledSlide key={item.id} onClick={item.onClick}>
                    <ImageCard variant={cardVariant} {...item} />
                </StyledSlide>
            ))}
        </Wrapper>
    );
};
