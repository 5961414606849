import styled from "@emotion/styled";
import placeholder from "./../../../assets/img/placeholder.svg";

export const PostImageWrapper = styled("div")<{
    backgroundUrl: string;
    isTransparent?: boolean;
}>`
    position: relative;
    height: 375px;
    background-image: url("${({ backgroundUrl }) =>
        backgroundUrl ?? placeholder}");
    background-size: ${({ isTransparent }) =>
        isTransparent ? "contain" : "cover"};
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    z-index: 1;
`;
