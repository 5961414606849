import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { store } from "./store/store";
import App from "./components/App/App";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import { logIn } from "./store/slices/auth";

const persistor = persistStore(store, {}, () => {
    if (
        process.env.NODE_ENV === "development" &&
        process.env.REACT_APP_DEV_ACCOUNT_TOKEN
    ) {
        store.dispatch(
            logIn({
                id: 15,
                name: "Test User",
                email: "test@mda.pl",
                token: process.env.REACT_APP_DEV_ACCOUNT_TOKEN,
            })
        );
    }
});

const container = document.getElementById("root");

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate persistor={persistor}>
                <App />
            </PersistGate>
        </Provider>
    </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
