import "swiper/css";
import "swiper/css/free-mode";
import styled from "@emotion/styled";
import placeholder from "./../../../assets/img/placeholder.svg";
import { IonCard, IonCardSubtitle, IonCardTitle } from "@ionic/react";

const Wrapper = styled("div")<{
    direction: "horizontal" | "vertical";
}>`
    display: flex;
    justify-content: flex-start;
    overflow: scroll;
    width: 100%;
    gap: 20px;
    flex-direction: ${({ direction }) =>
        direction === "vertical" ? "column" : "row"};
    ::-webkit-scrollbar {
        display: none;
    }
`;

const Card = styled(IonCard)`
    position: relative;
    width: 293px;
    height: 295px;
    margin-inline: 0;
    border-radius: 0;
    margin: 0;
    box-shadow: none;
    background: ${({ theme }) => theme.colors.white["1000"]};
`;

const Image = styled("img")<{
    isTransparent: boolean;
}>`
    height: 200px;
    width: 100%;
    background: ${({ theme }) => theme.colors.white["1000"]};
    object-fit: ${({ isTransparent }) => (isTransparent ? "contain" : "cover")};
`;

const ContentWrapper = styled("div")`
    height: 93px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 24px 16px;
`;

const SubTitle = styled(IonCardSubtitle)`
    ${({ theme }) => theme.text.paragraph.tiny}
    margin-top: -4px;
    padding: 0;
    color: ${({ theme }) => theme.colors.gray["640"]};
    margin-bottom: 4px;
    text-transform: none;
`;

const Title = styled(IonCardTitle)`
    ${({ theme }) => theme.text.h6}
    margin: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    text-align: center;
    color: ${({ theme }) => theme.colors.primary.gunmetal["1000"]};
`;

const StyledSwiperSlide = styled("div")``;

type ItemType = {
    id: number;
    title: string;
    subTitle: string;
    isTransparent: boolean;
    photo: string;
    onClick: () => void;
};

interface LargeCardSwiperProps {
    items: ItemType[];
    direction: "horizontal" | "vertical";
}

export const LargeCardScroll = (props: LargeCardSwiperProps) => {
    const { items, direction } = props;

    return (
        <Wrapper direction={direction}>
            {items.map((item) => (
                <StyledSwiperSlide key={item.id} onClick={item.onClick}>
                    <Card>
                        <Image
                            isTransparent={item.isTransparent}
                            alt={item.title}
                            src={item.photo ?? placeholder}
                        />
                        <ContentWrapper>
                            <SubTitle>{item.subTitle}</SubTitle>
                            <Title>{item.title}</Title>
                        </ContentWrapper>
                    </Card>
                </StyledSwiperSlide>
            ))}
        </Wrapper>
    );
};

export default LargeCardScroll;
