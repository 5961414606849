import React from "react";
import styled from "@emotion/styled";

const Text = styled("div")`
    ${({ theme }) => theme.text.paragraph.regular}
    margin-top: 24px;
    padding-bottom: 16px;
    color: ${({ theme }) => theme.colors.primary.gunmetal["640"]};
    ul {
        margin: 0 0 16px 0;
        padding: 0;
        list-style-position: inside;
        li {
            padding-left: 8px;
        }
    }
    p {
        margin: 0 0 16px 0;
    }
    img {
        max-width: 100%;
        height: auto;
    }
`;

type PostTextProps =
    | {
          content?: string;
          children?: never;
      }
    | {
          content?: never;
          children?: React.ReactNode | React.ReactNode[];
      };

export const PostText = (props: PostTextProps) => {
    const { content, children } = props;

    return content ? (
        <Text dangerouslySetInnerHTML={{ __html: content }} />
    ) : (
        <Text>{children}</Text>
    );
};
