import styled from "@emotion/styled";
import {
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardSubtitle,
    IonCardContent,
} from "@ionic/react";
import placeholder from "./../../../assets/img/placeholder.svg";
import { ReactNode } from "react";
import { IconTextItem, IconTextItemProps } from "../IconTextList/IconTextItem";

const Card = styled(IonCard)<{
    variant: "round" | "square";
    emphasis: boolean;
}>`
    position: relative;
    width: 208px;
    height: ${({ variant }) => (variant === "round" ? "240px" : "280px")};
    margin-inline: 0;
    border-radius: 0;
    margin: 0;
    box-shadow: none;
    background: ${({ variant, theme }) =>
        variant === "round"
            ? theme.colors.primary.aliceBlue
            : theme.colors.white["1000"]};

    ${({ emphasis, theme }) =>
        emphasis && {
            background: theme.colors.primary.gunmetal["1000"],
            color: theme.colors.white["1000"],
        }}
`;

const Image = styled("img")<{
    variant: "round" | "square";
    emphasis: boolean;
}>`
    background: ${({ theme }) => theme.colors.gray["300"]};
    margin: ${({ variant }) =>
        variant === "round" ? " 16px 0 8px 16px" : "0"};
    width: ${({ variant }) => (variant === "round" ? "128px" : "100%")};
    height: ${({ variant }) => (variant === "round" ? "128px" : "160px")};
    ${({ emphasis, variant }) =>
        emphasis &&
        variant === "round" && {
            width: "178px",
            height: "178px",
            margin: "0",
            position: "absolute",
            left: "7.69%",
            right: "7.69%",
            top: "-13.33%",
        }}
    object-fit: cover;
    border-radius: ${({ variant }) => (variant === "round" ? "50%" : "0")};
`;

const Header = styled(IonCardHeader)<{
    variant: "round" | "square";
    emphasis: boolean;
}>`
    padding: 12px 16px 16px;
    ${({ emphasis, variant, theme }) =>
        emphasis &&
        variant === "round" && {
            marginTop: "152px",
        }};
`;

const Title = styled(IonCardTitle)<{ emphasis: boolean }>`
    ${({ theme }) => theme.text.h6}
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 0;
    color: ${({ emphasis, theme }) =>
        emphasis
            ? theme.colors.white["1000"]
            : theme.colors.primary.gunmetal["1000"]};
`;

const SubTitle = styled(IonCardSubtitle)<{ emphasis: boolean }>`
    ${({ theme }) => theme.text.paragraph.tiny}
    margin-top: -4px;
    padding: 0;
    color: ${({ emphasis, theme }) =>
        emphasis ? theme.colors.white["640"] : theme.colors.gray["640"]};
    margin-bottom: 4px;
    text-transform: none;
`;

const Content = styled(IonCardContent)<{ emphasis: boolean }>`
    margin-top: -8px;
    padding: 16px;
    ${({ theme }) => theme.text.bold.tiny};
    color: ${({ emphasis, theme }) =>
        emphasis
            ? theme.colors.white["1000"]
            : theme.colors.primary.gunmetal["1000"]};
`;

const Footer = styled(IonCardContent)<{ emphasis: boolean }>`
    position: absolute;
    padding: 16px;
    bottom: 0;
    left: 0;
    right: 0;
    color: ${({ emphasis, theme }) =>
        emphasis ? theme.colors.gray["640"] : theme.colors.white["640"]};
`;

const Badge = styled("div")`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0 16px;

    position: absolute;
    height: 48px;
    border-radius: 40px;
    right: 16px;
    top: 16px;

    ${({ theme }) => theme.text.h7};
    background: ${({ theme }) => theme.colors.primary.oldLace};
    color: ${({ theme }) => theme.colors.primary.gunmetal["1000"]};
`;

export interface ImageCardProps {
    photo?: string;
    title: string;
    subTitle?: string;
    content?: ReactNode | ReactNode[];
    iconTextItem?: IconTextItemProps;
    priority?: boolean;
    variant: "round" | "square";
}

export const ImageCard = (props: ImageCardProps) => {
    const { variant, content, iconTextItem, title, photo, subTitle, priority } =
        props;
    const emphasis = !!priority;

    return (
        <Card variant={variant} emphasis={emphasis}>
            <Image
                emphasis={emphasis}
                variant={variant}
                alt={title}
                src={photo ?? placeholder}
            />
            <Header emphasis={emphasis} variant={variant}>
                <Title emphasis={emphasis}>{title}</Title>
                {!!subTitle && (
                    <SubTitle emphasis={emphasis}>{subTitle}</SubTitle>
                )}
            </Header>
            {!!content && <Content emphasis={emphasis}>{content}</Content>}
            {!!iconTextItem && (
                <Footer emphasis={emphasis}>
                    {iconTextItem.text && (
                        <IconTextItem {...iconTextItem} light={emphasis} />
                    )}
                </Footer>
            )}
            {!!priority && <Badge>PRIORYTET</Badge>}
        </Card>
    );
};
