import styled from "@emotion/styled";
import { IonCol } from "@ionic/react";
import Slider from "react-slick";
import placeholder from "./../../../assets/img/placeholder.svg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const StyledSlide = styled("div")`
    max-width: 380px;
    display: flex;
    min-height: 330px;
    align-items: center;
    justify-content: space-between;
    background: ${({ theme }) => theme.colors.white["1000"]};
    box-shadow: 8px 8px 40px -33px rgba(66, 68, 90, 1);
`;

const StyledIonCol = styled(IonCol)`
    display: flex;
    width: 100%;
    height: 100%;
    padding: 0;
    flex-direction: column;
    align-items: center;
`;

const SlideText = styled("h5")`
    ${({ theme }) => theme.text.h6}
    margin: 0;
    color: ${({ theme }) => theme.colors.gray["1000"]};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: center;
    width: 100%;
    padding: 4px 16px 0;
`;
const SlideSubheaderText = styled("p")`
    ${({ theme }) => theme.text.paragraph.tiny}
    margin: 0;
    color: ${({ theme }) => theme.colors.gray["640"]};
    margin-bottom: 2px;
`;
const ImageBox = styled("div")<{
    backgroundUrl?: string;
    isTransparent: boolean;
}>`
    width: 100%;
    height: 200px;
    overflow: hidden;
    margin-bottom: 16px;
    background-image: url(${({ backgroundUrl }) =>
        backgroundUrl ?? placeholder});
    background-position: center;
    background-size: ${({ isTransparent }) =>
        isTransparent ? "contain" : "cover"};
    background-repeat: no-repeat;
`;

interface SimpleSlider {
    items: {
        title: string;
        subTitle?: string;
        photo?: string;
        isTransparent: boolean;
        onClick: () => void;
        id: number;
    }[];
}
export const ReactSlick = (props: SimpleSlider) => {
    const { items } = props;
    const settings = {
        dots: true,
        arrows: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    return (
        <Slider {...settings}>
            {items.map((item) => (
                <StyledSlide onClick={item.onClick} key={item.id}>
                    <StyledIonCol>
                        <ImageBox
                            isTransparent={item.isTransparent}
                            backgroundUrl={item.photo}
                        ></ImageBox>
                        {!!item.subTitle && (
                            <SlideSubheaderText>
                                {item.subTitle}
                            </SlideSubheaderText>
                        )}
                        <SlideText>{item.title}</SlideText>
                    </StyledIonCol>
                </StyledSlide>
            ))}
        </Slider>
    );
};
