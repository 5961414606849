import styled from "@emotion/styled";

const ButtonIcon = styled("button")<{
    color: "light" | "dark";
}>`
    display: flex;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: transparent;
    border: 1px solid
        ${({ theme, color }) =>
            color === "light"
                ? theme.colors.white["240"]
                : theme.colors.gray["240"]};
    color: ${({ theme }) => theme.colors.white["1000"]};
    align-items: center;
    justify-content: center;
    cursor: pointer;
    svg {
        fill: ${({ color, theme }) =>
            color === "light"
                ? theme.colors.white["1000"]
                : theme.colors.gray["1000"]};
        stroke: ${({ color, theme }) =>
            color === "light"
                ? theme.colors.white["1000"]
                : theme.colors.gray["1000"]};
    }
`;

export default ButtonIcon;
