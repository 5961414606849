import React from "react";
import StyledSvg from "./StyledSvg";

const IconLogout = () => {
    return (
        <StyledSvg>
            <svg
                width="17"
                height="18"
                viewBox="0 0 17 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M9 1H3C1.89543 1 1 1.89543 1 3V15C1 16.1046 1.89543 17 3 17H9M6 9H16M16 9L14 6M16 9L14 12"
                    stroke="white"
                    strokeWidth="1.6"
                />
            </svg>
        </StyledSvg>
    );
};
export { IconLogout };
