export const Icon404 = () => {
    return (
        <svg
            width="287"
            height="122"
            viewBox="0 0 287 122"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M82.4392 113H67.8007V18.7718L72.6802 18.4926L27.928 84.1034V78.9383H98.3325V91.0832H17.3325V78.2403L65.57 9H82.4392V113Z"
                fill="white"
            />
            <path
                d="M155.028 117.99L150.445 118.9C149.953 118.998 149.451 119.033 148.95 119.004C146.582 118.87 144.52 117.342 143.703 115.115L122.21 56.5682C121.388 54.3313 121.182 51.915 121.613 49.5713L122.458 46.2265C122.875 44.5784 123.476 42.9825 124.25 41.4689L124.806 40.3809C125.057 39.8914 125.351 39.4255 125.685 38.9888C127.575 36.5172 128.078 33.253 127.018 30.327L121.207 14.2809C121.121 14.0424 120.858 13.919 120.619 14.0054C120.381 14.0918 120.117 13.9685 120.031 13.73L119.179 11.3777C118.965 10.7871 118.945 10.1437 119.12 9.54068L119.134 9.49392C119.412 8.54108 120.128 7.77803 121.061 7.44008L127.016 5.28337C127.949 4.94542 128.988 5.07313 129.811 5.62714L129.852 5.65433C130.373 6.00496 130.769 6.51226 130.983 7.10289L131.835 9.45513C131.921 9.69364 131.798 9.95701 131.559 10.0434C131.321 10.1298 131.198 10.3931 131.284 10.6316L136.299 24.4796L137.227 27.0422C138.227 29.8047 140.33 32.0279 143.032 33.1808C146.039 34.3126 148.908 35.7827 151.582 37.5627L152.706 38.3108C154.715 39.7591 156.232 41.7872 157.055 44.1229L177.405 101.889C178.375 104.644 177.516 107.713 175.255 109.564C174.92 109.838 174.56 110.08 174.18 110.287L170.543 112.27C168.275 113.506 165.917 114.568 163.488 115.448L161.263 116.253C159.232 116.989 157.147 117.57 155.028 117.99Z"
                fill="white"
            />
            <path
                d="M131.333 24.8818L132.411 27.8611C134.327 33.1502 138.339 37.4153 143.501 39.6505L144.098 39.9088C145.041 40.3174 145.938 40.8259 146.773 41.4257V41.4257C149.129 43.1176 150.918 45.482 151.905 48.2087L158.446 66.2702"
                stroke="#222D38"
                strokeWidth="2"
                stroke-linecap="round"
                strokeLinejoin="bevel"
            />
            <path
                d="M118.454 11.368C118.454 11.368 111.866 22.7902 114.867 27.7343C117.869 32.6783 120.1 24.5802 118.205 21.3107C116.309 18.0411 118.454 11.368 118.454 11.368Z"
                fill="white"
            />
            <path
                d="M253.774 113H239.136V18.7718L244.015 18.4926L199.263 84.1034V78.9383H269.667V91.0832H188.667V78.2403L236.905 9H253.774V113Z"
                fill="white"
            />
        </svg>
    );
};
