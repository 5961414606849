import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { PostText } from "../../Layout/Post/PostText";
import { PostTitle } from "../../Layout/Post/PostTitle";
import { PostExcerpt } from "../../Layout/Post/PostExcerpt";
import { PostContentWrapper } from "../../Layout/Post/PostContentWrapper";
import {
    IconGlass,
    IconArticle,
    IconList,
    IconTaste,
} from "../../Icons/FullIconsList";
import Stars from "../../Controls/Stars/Stars";
import { LevelHelper } from "../../../helpers/LevelHelper";
import { Cocktail } from "../../../types/Cocktail";
import { IconTextList } from "../../Layout/IconTextList/IconTextList";
import { IconTextItem } from "../../Layout/IconTextList/IconTextItem";
import { PostImageWrapper } from "../../Layout/Post/PostImageWrapper";
import { PageContentWrapper } from "../../Layout/Page/PageContentWrapper";
import { devices } from "../../../theme/devices";

const SectionTitle = styled("h3")`
    ${({ theme }) => theme.text.h5}
    margin: 0;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.primary.gunmetal["1000"]};
`;

const SectionTitleIcon = styled("span")`
    margin: 0;
    display: flex;
    align-items: center;
    margin-right: 8px;
`;

const HeaderWrapper = styled("div")`
    display: flex;
    flex-direction: column;
    @media only screen and (min-width: ${devices.tablet}) {
        flex-direction: row;
        justify-content: space-between;
        gap: 20px;
    }
`;

const ShortDescription = styled("p")`
    margin: 0 0 34px;
    ${({ theme }) => theme.text.paragraph.regular}
    color: ${({ theme }) => theme.colors.primary.gunmetal["640"]};
`;

interface ArticleContentProps {
    cocktail: Cocktail;
}

export const CocktailContent = (props: ArticleContentProps) => {
    const { cocktail } = props;
    const theme = useTheme();
    const stars = LevelHelper(cocktail.difficulty_level);

    return (
        <>
            <PostImageWrapper
                isTransparent={cocktail.is_transparent}
                backgroundUrl={cocktail.photo_path}
            />
            <PostContentWrapper>
                <PageContentWrapper>
                    <HeaderWrapper>
                        <PostTitle>{cocktail.name}</PostTitle>
                        {cocktail.difficulty_level && (
                            <IconTextList>
                                <IconTextItem
                                    text={`Stopień trudności: ${cocktail.difficulty_level}`}
                                    icon={<Stars stars={stars} />}
                                />
                            </IconTextList>
                        )}
                    </HeaderWrapper>

                    <IconTextList>
                        {cocktail.taste && (
                            <IconTextItem
                                text={`Smak: ${cocktail.taste}`}
                                icon={
                                    <IconTaste
                                        height={16}
                                        color={
                                            theme.colors.primary.gunmetal["400"]
                                        }
                                    />
                                }
                            />
                        )}
                        {cocktail.glass_type && (
                            <IconTextItem
                                text={`Typ szkła: ${cocktail.glass_type}`}
                                icon={
                                    <IconGlass
                                        height={15}
                                        color={
                                            theme.colors.primary.gunmetal["400"]
                                        }
                                    />
                                }
                            />
                        )}
                    </IconTextList>

                    <PostExcerpt />
                    {cocktail.short_description && (
                        <ShortDescription>
                            {cocktail.short_description}
                        </ShortDescription>
                    )}
                    {cocktail.ingredients && (
                        <>
                            <SectionTitle>
                                <SectionTitleIcon>
                                    <IconArticle
                                        height={20}
                                        color={
                                            theme.colors.primary.gunmetal[
                                                "1000"
                                            ]
                                        }
                                    />
                                </SectionTitleIcon>{" "}
                                Składniki
                            </SectionTitle>
                            <PostText>
                                <ul>
                                    {cocktail.ingredients.map((ingredient) => (
                                        <li key={ingredient.id}>
                                            {ingredient.name}
                                        </li>
                                    ))}
                                </ul>
                            </PostText>
                        </>
                    )}
                    <SectionTitle>
                        <SectionTitleIcon>
                            <IconList
                                height={20}
                                color={theme.colors.primary.gunmetal["1000"]}
                            />
                        </SectionTitleIcon>{" "}
                        Przygotowanie
                    </SectionTitle>
                    <PostText content={cocktail.description} />
                </PageContentWrapper>
            </PostContentWrapper>
        </>
    );
};
