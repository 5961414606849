import { IonContent } from "@ionic/react";
import {
    HeaderMain,
    HeaderTitle,
} from "../../components/Layout/Header/HeaderMainStyles";
import { useGetQuizQuery } from "../../services/quiz";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { QuizContent } from "../../components/Quizzes/Quiz/QuizContent";
import { Redirect, useParams } from "react-router";
import { StyledIonPage } from "../Articles/ArticlePage";
import { GoBackButton } from "../../components/Controls/Buttons/GoBackButton";
import { useEffect } from "react";
import { useAppSelector } from "../../hooks/redux/useAppSelector";
import { useAppDispatch } from "../../hooks/redux/useAppDispatch";
import { resetQuiz } from "../../store/slices/quiz";
import { numericIdHelper } from "../../helpers/numericIdHelper";

export const QuizPage = () => {
    const params = useParams<{ id: string }>();
    const quizId = numericIdHelper(params.id);

    const dispatch = useAppDispatch();
    const savedQuizId = useAppSelector((state) => state.quiz.quiz?.id);

    const {
        data: quiz,
        isFetching,
        isSuccess,
        isError,
    } = useGetQuizQuery(quizId ?? skipToken);

    useEffect(() => {
        if (savedQuizId !== quizId) {
            dispatch(resetQuiz());
        }
    }, [dispatch, quizId, savedQuizId]);

    if ((isError && savedQuizId !== quizId) || quizId === null)
        return <Redirect to="/404" />;

    return (
        <StyledIonPage>
            <IonContent fullscreen>
                <HeaderMain size="small">
                    <GoBackButton
                        additionalAction={() => dispatch(resetQuiz())}
                    />
                    {isSuccess && !isFetching && (
                        <HeaderTitle>{quiz.name}</HeaderTitle>
                    )}
                </HeaderMain>
                <QuizContent />
            </IonContent>
        </StyledIonPage>
    );
};
