import { useIonRouter } from "@ionic/react";
import React from "react";

import ButtonIcon from "./ButtonIcon";
import { IconChevronLeft } from "../../Icons/IconChevronLeft";

interface GoBackButtonProps {
    additionalAction?: () => void;
}

export const GoBackButton = (props: GoBackButtonProps) => {
    const { additionalAction } = props;
    const router = useIonRouter();

    const handleButtonClick = () => {
        if (additionalAction) {
            additionalAction();
        }
        router.goBack();
    };

    return (
        <ButtonIcon color="light" onClick={handleButtonClick}>
            <IconChevronLeft />
        </ButtonIcon>
    );
};
