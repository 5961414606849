interface IconChevronLeftProps {
    color?: string;
}

export const IconChevronLeft = ({ color }: IconChevronLeftProps) => {
    return (
        <svg
            width="8"
            height="14"
            viewBox="0 0 8 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clipRule="evenodd"
                d="M2.41411 7L7.84842 1.56568L6.71705 0.434311L0.151367 7L6.71705 13.5657L7.84842 12.4343L2.41411 7Z"
                fill={color ? color : `#FFFFFF`}
                stroke="none"
            />
        </svg>
    );
};
